import axios from "axios";
import ReactGA from "react-ga4";

export function createAxiosWithAuth() {
  const version = process.env.REACT_APP_VERSION;

  const authMiddleware = (config) => {
    const token = localStorage.getItem("authToken");
    const login = localStorage.getItem("userLogin");

    if (token) {
      config.headers.PortalAuth = token;

      if (version === "NZT") {
        ReactGA.ga('set', 'user_id', login);
      }
    }

    return config;
  };

  const axiosInstanceWithAuth = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  axiosInstanceWithAuth.interceptors.request.use(authMiddleware);

  return axiosInstanceWithAuth;
}

export function createAxios() {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  return axiosInstance;
}
